export const continents = [
  {
    "continent_code": "AS",
    "country_code": "AF",
    "continent_name": "Asia",
    "country_name": "Afghanistan"
  },
  {
    "continent_code": "EU",
    "country_code": "AX",
    "continent_name": "Europe",
    "country_name": "Åland Islands"
  },
  {
    "continent_code": "EU",
    "country_code": "AL",
    "continent_name": "Europe",
    "country_name": "Albania"
  },
  {
    "continent_code": "AF",
    "country_code": "DZ",
    "continent_name": "Africa",
    "country_name": "Algeria"
  },
  {
    "continent_code": "OC",
    "country_code": "AS",
    "continent_name": "Oceania",
    "country_name": "American Samoa"
  },
  {
    "continent_code": "EU",
    "country_code": "AD",
    "continent_name": "Europe",
    "country_name": "Andorra"
  },
  {
    "continent_code": "AF",
    "country_code": "AO",
    "continent_name": "Africa",
    "country_name": "Angola"
  },
  {
    "continent_code": "NA",
    "country_code": "AI",
    "continent_name": "North America",
    "country_name": "Anguilla"
  },
  {
    "continent_code": "AN",
    "country_code": "AQ",
    "continent_name": "Antarctica",
    "country_name": "Antarctica"
  },
  {
    "continent_code": "NA",
    "country_code": "AG",
    "continent_name": "North America",
    "country_name": "Antigua and Barbuda"
  },
  {
    "continent_code": "SA",
    "country_code": "AR",
    "continent_name": "South America",
    "country_name": "Argentina"
  },
  {
    "continent_code": "AS",
    "country_code": "AM",
    "continent_name": "Asia",
    "country_name": "Armenia"
  },
  {
    "continent_code": "NA",
    "country_code": "AW",
    "continent_name": "North America",
    "country_name": "Aruba"
  },
  {
    "continent_code": "OC",
    "country_code": "AU",
    "continent_name": "Oceania",
    "country_name": "Australia"
  },
  {
    "continent_code": "EU",
    "country_code": "AT",
    "continent_name": "Europe",
    "country_name": "Austria"
  },
  {
    "continent_code": "AS",
    "country_code": "AZ",
    "continent_name": "Asia",
    "country_name": "Azerbaijan"
  },
  {
    "continent_code": "NA",
    "country_code": "BS",
    "continent_name": "North America",
    "country_name": "Bahamas"
  },
  {
    "continent_code": "AS",
    "country_code": "BH",
    "continent_name": "Asia",
    "country_name": "Bahrain"
  },
  {
    "continent_code": "AS",
    "country_code": "BD",
    "continent_name": "Asia",
    "country_name": "Bangladesh"
  },
  {
    "continent_code": "NA",
    "country_code": "BB",
    "continent_name": "North America",
    "country_name": "Barbados"
  },
  {
    "continent_code": "EU",
    "country_code": "BY",
    "continent_name": "Europe",
    "country_name": "Belarus"
  },
  {
    "continent_code": "EU",
    "country_code": "BE",
    "continent_name": "Europe",
    "country_name": "Belgium"
  },
  {
    "continent_code": "NA",
    "country_code": "BZ",
    "continent_name": "North America",
    "country_name": "Belize"
  },
  {
    "continent_code": "AF",
    "country_code": "BJ",
    "continent_name": "Africa",
    "country_name": "Benin"
  },
  {
    "continent_code": "NA",
    "country_code": "BM",
    "continent_name": "North America",
    "country_name": "Bermuda"
  },
  {
    "continent_code": "AS",
    "country_code": "BT",
    "continent_name": "Asia",
    "country_name": "Bhutan"
  },
  {
    "continent_code": "SA",
    "country_code": "BO",
    "continent_name": "South America",
    "country_name": "Bolivia"
  },
  {
    "continent_code": "NA",
    "country_code": "BQ",
    "continent_name": "North America",
    "country_name": "Bonaire, Sint Eustatius and Saba"
  },
  {
    "continent_code": "EU",
    "country_code": "BA",
    "continent_name": "Europe",
    "country_name": "Bosnia and Herzegovina"
  },
  {
    "continent_code": "AF",
    "country_code": "BW",
    "continent_name": "Africa",
    "country_name": "Botswana"
  },
  {
    "continent_code": "AN",
    "country_code": "BV",
    "continent_name": "Antarctica",
    "country_name": "Bouvet Island Bouvetoya"
  },
  {
    "continent_code": "SA",
    "country_code": "BR",
    "continent_name": "South America",
    "country_name": "Brazil"
  },
  {
    "continent_code": "AS",
    "country_code": "IO",
    "continent_name": "Asia",
    "country_name": "British Indian Ocean Territory Chagos Archipelago"
  },
  {
    "continent_code": "NA",
    "country_code": "VG",
    "continent_name": "North America",
    "country_name": "British Virgin Islands"
  },
  {
    "continent_code": "AS",
    "country_code": "BN",
    "continent_name": "Asia",
    "country_name": "Brunei Darussalam"
  },
  {
    "continent_code": "EU",
    "country_code": "BG",
    "continent_name": "Europe",
    "country_name": "Bulgaria"
  },
  {
    "continent_code": "AF",
    "country_code": "BF",
    "continent_name": "Africa",
    "country_name": "Burkina Faso"
  },
  {
    "continent_code": "AF",
    "country_code": "BI",
    "continent_name": "Africa",
    "country_name": "Burundi"
  },
  {
    "continent_code": "AS",
    "country_code": "KH",
    "continent_name": "Asia",
    "country_name": "Cambodia"
  },
  {
    "continent_code": "AF",
    "country_code": "CM",
    "continent_name": "Africa",
    "country_name": "Cameroon"
  },
  {
    "continent_code": "NA",
    "country_code": "CA",
    "continent_name": "North America",
    "country_name": "Canada"
  },
  {
    "continent_code": "AF",
    "country_code": "CV",
    "continent_name": "Africa",
    "country_name": "Cape Verde"
  },
  {
    "continent_code": "NA",
    "country_code": "KY",
    "continent_name": "North America",
    "country_name": "Cayman Islands"
  },
  {
    "continent_code": "AF",
    "country_code": "CF",
    "continent_name": "Africa",
    "country_name": "Central African Republic"
  },
  {
    "continent_code": "AF",
    "country_code": "TD",
    "continent_name": "Africa",
    "country_name": "Chad"
  },
  {
    "continent_code": "SA",
    "country_code": "CL",
    "continent_name": "South America",
    "country_name": "Chile"
  },
  {
    "continent_code": "AS",
    "country_code": "CN",
    "continent_name": "Asia",
    "country_name": "China"
  },
  {
    "continent_code": "AS",
    "country_code": "CX",
    "continent_name": "Asia",
    "country_name": "Christmas Island"
  },
  {
    "continent_code": "AS",
    "country_code": "CC",
    "continent_name": "Asia",
    "country_name": "Cocos Keeling Islands"
  },
  {
    "continent_code": "SA",
    "country_code": "CO",
    "continent_name": "South America",
    "country_name": "Colombia"
  },
  {
    "continent_code": "AF",
    "country_code": "KM",
    "continent_name": "Africa",
    "country_name": "Comoros"
  },
  {
    "continent_code": "AF",
    "country_code": "CD",
    "continent_name": "Africa",
    "country_name": "Congo"
  },
  {
    "continent_code": "AF",
    "country_code": "CG",
    "continent_name": "Africa",
    "country_name": "Congo"
  },
  {
    "continent_code": "OC",
    "country_code": "CK",
    "continent_name": "Oceania",
    "country_name": "Cook Islands"
  },
  {
    "continent_code": "NA",
    "country_code": "CR",
    "continent_name": "North America",
    "country_name": "Costa Rica"
  },
  {
    "continent_code": "AF",
    "country_code": "CI",
    "continent_name": "Africa",
    "country_name": "Côte d'Ivoire"
  },
  {
    "continent_code": "EU",
    "country_code": "HR",
    "continent_name": "Europe",
    "country_name": "Croatia"
  },
  {
    "continent_code": "NA",
    "country_code": "CU",
    "continent_name": "North America",
    "country_name": "Cuba"
  },
  {
    "continent_code": "NA",
    "country_code": "CW",
    "continent_name": "North America",
    "country_name": "Curaçao"
  },
  {
    "continent_code": "AS",
    "country_code": "CY",
    "continent_name": "Asia",
    "country_name": "Cyprus"
  },
  {
    "continent_code": "EU",
    "country_code": "CZ",
    "continent_name": "Europe",
    "country_name": "Czech Republic"
  },
  {
    "continent_code": "EU",
    "country_code": "DK",
    "continent_name": "Europe",
    "country_name": "Denmark"
  },
  {
    "continent_code": "AF",
    "country_code": "DJ",
    "continent_name": "Africa",
    "country_name": "Djibouti"
  },
  {
    "continent_code": "NA",
    "country_code": "DM",
    "continent_name": "North America",
    "country_name": "Dominica"
  },
  {
    "continent_code": "NA",
    "country_code": "DO",
    "continent_name": "North America",
    "country_name": "Dominican Republic"
  },
  {
    "continent_code": "SA",
    "country_code": "EC",
    "continent_name": "South America",
    "country_name": "Ecuador"
  },
  {
    "continent_code": "AF",
    "country_code": "EG",
    "continent_name": "Africa",
    "country_name": "Egypt"
  },
  {
    "continent_code": "NA",
    "country_code": "SV",
    "continent_name": "North America",
    "country_name": "El Salvador"
  },
  {
    "continent_code": "AF",
    "country_code": "GQ",
    "continent_name": "Africa",
    "country_name": "Equatorial Guinea"
  },
  {
    "continent_code": "AF",
    "country_code": "ER",
    "continent_name": "Africa",
    "country_name": "Eritrea"
  },
  {
    "continent_code": "EU",
    "country_code": "EE",
    "continent_name": "Europe",
    "country_name": "Estonia"
  },
  {
    "continent_code": "AF",
    "country_code": "ET",
    "continent_name": "Africa",
    "country_name": "Ethiopia"
  },
  {
    "continent_code": "EU",
    "country_code": "FO",
    "continent_name": "Europe",
    "country_name": "Faroe Islands"
  },
  {
    "continent_code": "SA",
    "country_code": "FK",
    "continent_name": "South America",
    "country_name": "Falkland Islands Malvinas"
  },
  {
    "continent_code": "OC",
    "country_code": "FJ",
    "continent_name": "Oceania",
    "country_name": "Fiji"
  },
  {
    "continent_code": "EU",
    "country_code": "FI",
    "continent_name": "Europe",
    "country_name": "Finland"
  },
  {
    "continent_code": "EU",
    "country_code": "FR",
    "continent_name": "Europe",
    "country_name": "France"
  },
  {
    "continent_code": "SA",
    "country_code": "GF",
    "continent_name": "South America",
    "country_name": "French Guiana"
  },
  {
    "continent_code": "OC",
    "country_code": "PF",
    "continent_name": "Oceania",
    "country_name": "French Polynesia"
  },
  {
    "continent_code": "AN",
    "country_code": "TF",
    "continent_name": "Antarctica",
    "country_name": "French Southern Territories"
  },
  {
    "continent_code": "AF",
    "country_code": "GA",
    "continent_name": "Africa",
    "country_name": "Gabon"
  },
  {
    "continent_code": "AF",
    "country_code": "GM",
    "continent_name": "Africa",
    "country_name": "Gambia"
  },
  {
    "continent_code": "AS",
    "country_code": "GE",
    "continent_name": "Asia",
    "country_name": "Georgia"
  },
  {
    "continent_code": "EU",
    "country_code": "DE",
    "continent_name": "Europe",
    "country_name": "Germany"
  },
  {
    "continent_code": "AF",
    "country_code": "GH",
    "continent_name": "Africa",
    "country_name": "Ghana"
  },
  {
    "continent_code": "EU",
    "country_code": "GI",
    "continent_name": "Europe",
    "country_name": "Gibraltar"
  },
  {
    "continent_code": "EU",
    "country_code": "GR",
    "continent_name": "Europe",
    "country_name": "Greece"
  },
  {
    "continent_code": "NA",
    "country_code": "GL",
    "continent_name": "North America",
    "country_name": "Greenland"
  },
  {
    "continent_code": "NA",
    "country_code": "GD",
    "continent_name": "North America",
    "country_name": "Grenada"
  },
  {
    "continent_code": "NA",
    "country_code": "GP",
    "continent_name": "North America",
    "country_name": "Guadeloupe"
  },
  {
    "continent_code": "OC",
    "country_code": "GU",
    "continent_name": "Oceania",
    "country_name": "Guam"
  },
  {
    "continent_code": "NA",
    "country_code": "GT",
    "continent_name": "North America",
    "country_name": "Guatemala"
  },
  {
    "continent_code": "EU",
    "country_code": "GG",
    "continent_name": "Europe",
    "country_name": "Guernsey"
  },
  {
    "continent_code": "AF",
    "country_code": "GN",
    "continent_name": "Africa",
    "country_name": "Guinea"
  },
  {
    "continent_code": "AF",
    "country_code": "GW",
    "continent_name": "Africa",
    "country_name": "Guinea-Bissau"
  },
  {
    "continent_code": "SA",
    "country_code": "GY",
    "continent_name": "South America",
    "country_name": "Guyana"
  },
  {
    "continent_code": "NA",
    "country_code": "HT",
    "continent_name": "North America",
    "country_name": "Haiti"
  },
  {
    "continent_code": "AN",
    "country_code": "HM",
    "continent_name": "Antarctica",
    "country_name": "Heard Island and McDonald Islands"
  },
  {
    "continent_code": "EU",
    "country_code": "VA",
    "continent_name": "Europe",
    "country_name": "Holy See Vatican City State"
  },
  {
    "continent_code": "NA",
    "country_code": "HN",
    "continent_name": "North America",
    "country_name": "Honduras"
  },
  {
    "continent_code": "AS",
    "country_code": "HK",
    "continent_name": "Asia",
    "country_name": "Hong Kong"
  },
  {
    "continent_code": "EU",
    "country_code": "HU",
    "continent_name": "Europe",
    "country_name": "Hungary"
  },
  {
    "continent_code": "EU",
    "country_code": "IS",
    "continent_name": "Europe",
    "country_name": "Iceland"
  },
  {
    "continent_code": "AS",
    "country_code": "IN",
    "continent_name": "Asia",
    "country_name": "India"
  },
  {
    "continent_code": "AS",
    "country_code": "ID",
    "continent_name": "Asia",
    "country_name": "Indonesia"
  },
  {
    "continent_code": "AS",
    "country_code": "IR",
    "continent_name": "Asia",
    "country_name": "Iran"
  },
  {
    "continent_code": "AS",
    "country_code": "IQ",
    "continent_name": "Asia",
    "country_name": "Iraq"
  },
  {
    "continent_code": "EU",
    "country_code": "IE",
    "continent_name": "Europe",
    "country_name": "Ireland"
  },
  {
    "continent_code": "EU",
    "country_code": "IM",
    "continent_name": "Europe",
    "country_name": "Isle of Man"
  },
  {
    "continent_code": "AS",
    "country_code": "IL",
    "continent_name": "Asia",
    "country_name": "Israel"
  },
  {
    "continent_code": "EU",
    "country_code": "IT",
    "continent_name": "Europe",
    "country_name": "Italy"
  },
  {
    "continent_code": "NA",
    "country_code": "JM",
    "continent_name": "North America",
    "country_name": "Jamaica"
  },
  {
    "continent_code": "AS",
    "country_code": "JP",
    "continent_name": "Asia",
    "country_name": "Japan"
  },
  {
    "continent_code": "EU",
    "country_code": "JE",
    "continent_name": "Europe",
    "country_name": "Jersey"
  },
  {
    "continent_code": "AS",
    "country_code": "JO",
    "continent_name": "Asia",
    "country_name": "Jordan"
  },
  {
    "continent_code": "AS",
    "country_code": "KZ",
    "continent_name": "Asia",
    "country_name": "Kazakhstan"
  },
  {
    "continent_code": "AF",
    "country_code": "KE",
    "continent_name": "Africa",
    "country_name": "Kenya"
  },
  {
    "continent_code": "OC",
    "country_code": "KI",
    "continent_name": "Oceania",
    "country_name": "Kiribati"
  },
  {
    "continent_code": "AS",
    "country_code": "KP",
    "continent_name": "Asia",
    "country_name": "Korea"
  },
  {
    "continent_code": "AS",
    "country_code": "KR",
    "continent_name": "Asia",
    "country_name": "Korea"
  },
  {
    "continent_code": "AS",
    "country_code": "KW",
    "continent_name": "Asia",
    "country_name": "Kuwait"
  },
  {
    "continent_code": "AS",
    "country_code": "KG",
    "continent_name": "Asia",
    "country_name": "Kyrgyz Republic"
  },
  {
    "continent_code": "AS",
    "country_code": "LA",
    "continent_name": "Asia",
    "country_name": "Lao Peoples Democratic Republic"
  },
  {
    "continent_code": "EU",
    "country_code": "LV",
    "continent_name": "Europe",
    "country_name": "Latvia"
  },
  {
    "continent_code": "AS",
    "country_code": "LB",
    "continent_name": "Asia",
    "country_name": "Lebanon"
  },
  {
    "continent_code": "AF",
    "country_code": "LS",
    "continent_name": "Africa",
    "country_name": "Lesotho"
  },
  {
    "continent_code": "AF",
    "country_code": "LR",
    "continent_name": "Africa",
    "country_name": "Liberia"
  },
  {
    "continent_code": "AF",
    "country_code": "LY",
    "continent_name": "Africa",
    "country_name": "Libya"
  },
  {
    "continent_code": "EU",
    "country_code": "LI",
    "continent_name": "Europe",
    "country_name": "Liechtenstein"
  },
  {
    "continent_code": "EU",
    "country_code": "LT",
    "continent_name": "Europe",
    "country_name": "Lithuania"
  },
  {
    "continent_code": "EU",
    "country_code": "LU",
    "continent_name": "Europe",
    "country_name": "Luxembourg"
  },
  {
    "continent_code": "AS",
    "country_code": "MO",
    "continent_name": "Asia",
    "country_name": "Macao"
  },
  {
    "continent_code": "EU",
    "country_code": "MK",
    "continent_name": "Europe",
    "country_name": "Macedonia"
  },
  {
    "continent_code": "AF",
    "country_code": "MG",
    "continent_name": "Africa",
    "country_name": "Madagascar"
  },
  {
    "continent_code": "AF",
    "country_code": "MW",
    "continent_name": "Africa",
    "country_name": "Malawi"
  },
  {
    "continent_code": "AS",
    "country_code": "MY",
    "continent_name": "Asia",
    "country_name": "Malaysia"
  },
  {
    "continent_code": "AS",
    "country_code": "MV",
    "continent_name": "Asia",
    "country_name": "Maldives"
  },
  {
    "continent_code": "AF",
    "country_code": "ML",
    "continent_name": "Africa",
    "country_name": "Mali"
  },
  {
    "continent_code": "EU",
    "country_code": "MT",
    "continent_name": "Europe",
    "country_name": "Malta"
  },
  {
    "continent_code": "OC",
    "country_code": "MH",
    "continent_name": "Oceania",
    "country_name": "Marshall Islands"
  },
  {
    "continent_code": "NA",
    "country_code": "MQ",
    "continent_name": "North America",
    "country_name": "Martinique"
  },
  {
    "continent_code": "AF",
    "country_code": "MR",
    "continent_name": "Africa",
    "country_name": "Mauritania"
  },
  {
    "continent_code": "AF",
    "country_code": "MU",
    "continent_name": "Africa",
    "country_name": "Mauritius"
  },
  {
    "continent_code": "AF",
    "country_code": "YT",
    "continent_name": "Africa",
    "country_name": "Mayotte"
  },
  {
    "continent_code": "NA",
    "country_code": "MX",
    "continent_name": "North America",
    "country_name": "Mexico"
  },
  {
    "continent_code": "OC",
    "country_code": "FM",
    "continent_name": "Oceania",
    "country_name": "Micronesia"
  },
  {
    "continent_code": "EU",
    "country_code": "MD",
    "continent_name": "Europe",
    "country_name": "Moldova"
  },
  {
    "continent_code": "EU",
    "country_code": "MC",
    "continent_name": "Europe",
    "country_name": "Monaco"
  },
  {
    "continent_code": "AS",
    "country_code": "MN",
    "continent_name": "Asia",
    "country_name": "Mongolia"
  },
  {
    "continent_code": "EU",
    "country_code": "ME",
    "continent_name": "Europe",
    "country_name": "Montenegro"
  },
  {
    "continent_code": "NA",
    "country_code": "MS",
    "continent_name": "North America",
    "country_name": "Montserrat"
  },
  {
    "continent_code": "AF",
    "country_code": "MA",
    "continent_name": "Africa",
    "country_name": "Morocco"
  },
  {
    "continent_code": "AF",
    "country_code": "MZ",
    "continent_name": "Africa",
    "country_name": "Mozambique"
  },
  {
    "continent_code": "AS",
    "country_code": "MM",
    "continent_name": "Asia",
    "country_name": "Myanmar"
  },
  {
    "continent_code": "AF",
    "country_code": "NA",
    "continent_name": "Africa",
    "country_name": "Namibia"
  },
  {
    "continent_code": "OC",
    "country_code": "NR",
    "continent_name": "Oceania",
    "country_name": "Nauru"
  },
  {
    "continent_code": "AS",
    "country_code": "NP",
    "continent_name": "Asia",
    "country_name": "Nepal"
  },
  {
    "continent_code": "EU",
    "country_code": "NL",
    "continent_name": "Europe",
    "country_name": "Netherlands"
  },
  {
    "continent_code": "OC",
    "country_code": "NC",
    "continent_name": "Oceania",
    "country_name": "New Caledonia"
  },
  {
    "continent_code": "OC",
    "country_code": "NZ",
    "continent_name": "Oceania",
    "country_name": "New Zealand"
  },
  {
    "continent_code": "NA",
    "country_code": "NI",
    "continent_name": "North America",
    "country_name": "Nicaragua"
  },
  {
    "continent_code": "AF",
    "country_code": "NE",
    "continent_name": "Africa",
    "country_name": "Niger"
  },
  {
    "continent_code": "AF",
    "country_code": "NG",
    "continent_name": "Africa",
    "country_name": "Nigeria"
  },
  {
    "continent_code": "OC",
    "country_code": "NU",
    "continent_name": "Oceania",
    "country_name": "Niue"
  },
  {
    "continent_code": "OC",
    "country_code": "NF",
    "continent_name": "Oceania",
    "country_name": "Norfolk Island"
  },
  {
    "continent_code": "OC",
    "country_code": "MP",
    "continent_name": "Oceania",
    "country_name": "Northern Mariana Islands"
  },
  {
    "continent_code": "EU",
    "country_code": "NO",
    "continent_name": "Europe",
    "country_name": "Norway"
  },
  {
    "continent_code": "AS",
    "country_code": "OM",
    "continent_name": "Asia",
    "country_name": "Oman"
  },
  {
    "continent_code": "AS",
    "country_code": "PK",
    "continent_name": "Asia",
    "country_name": "Pakistan"
  },
  {
    "continent_code": "OC",
    "country_code": "PW",
    "continent_name": "Oceania",
    "country_name": "Palau"
  },
  {
    "continent_code": "AS",
    "country_code": "PS",
    "continent_name": "Asia",
    "country_name": "Palestinian Territory"
  },
  {
    "continent_code": "NA",
    "country_code": "PA",
    "continent_name": "North America",
    "country_name": "Panama"
  },
  {
    "continent_code": "OC",
    "country_code": "PG",
    "continent_name": "Oceania",
    "country_name": "Papua New Guinea"
  },
  {
    "continent_code": "SA",
    "country_code": "PY",
    "continent_name": "South America",
    "country_name": "Paraguay"
  },
  {
    "continent_code": "SA",
    "country_code": "PE",
    "continent_name": "South America",
    "country_name": "Peru"
  },
  {
    "continent_code": "AS",
    "country_code": "PH",
    "continent_name": "Asia",
    "country_name": "Philippines"
  },
  {
    "continent_code": "OC",
    "country_code": "PN",
    "continent_name": "Oceania",
    "country_name": "Pitcairn Islands"
  },
  {
    "continent_code": "EU",
    "country_code": "PL",
    "continent_name": "Europe",
    "country_name": "Poland"
  },
  {
    "continent_code": "EU",
    "country_code": "PT",
    "continent_name": "Europe",
    "country_name": "Portugal"
  },
  {
    "continent_code": "NA",
    "country_code": "PR",
    "continent_name": "North America",
    "country_name": "Puerto Rico"
  },
  {
    "continent_code": "AS",
    "country_code": "QA",
    "continent_name": "Asia",
    "country_name": "Qatar"
  },
  {
    "continent_code": "AF",
    "country_code": "RE",
    "continent_name": "Africa",
    "country_name": "Réunion"
  },
  {
    "continent_code": "EU",
    "country_code": "RO",
    "continent_name": "Europe",
    "country_name": "Romania"
  },
  {
    "continent_code": "EU",
    "country_code": "RU",
    "continent_name": "Europe",
    "country_name": "Russian Federation"
  },
  {
    "continent_code": "AF",
    "country_code": "RW",
    "continent_name": "Africa",
    "country_name": "Rwanda"
  },
  {
    "continent_code": "NA",
    "country_code": "BL",
    "continent_name": "North America",
    "country_name": "Saint Barthélemy"
  },
  {
    "continent_code": "AF",
    "country_code": "SH",
    "continent_name": "Africa",
    "country_name": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    "continent_code": "NA",
    "country_code": "KN",
    "continent_name": "North America",
    "country_name": "Saint Kitts and Nevis"
  },
  {
    "continent_code": "NA",
    "country_code": "LC",
    "continent_name": "North America",
    "country_name": "Saint Lucia"
  },
  {
    "continent_code": "NA",
    "country_code": "MF",
    "continent_name": "North America",
    "country_name": "Saint Martin"
  },
  {
    "continent_code": "NA",
    "country_code": "PM",
    "continent_name": "North America",
    "country_name": "Saint Pierre and Miquelon"
  },
  {
    "continent_code": "NA",
    "country_code": "VC",
    "continent_name": "North America",
    "country_name": "Saint Vincent and the Grenadines"
  },
  {
    "continent_code": "OC",
    "country_code": "WS",
    "continent_name": "Oceania",
    "country_name": "Samoa"
  },
  {
    "continent_code": "EU",
    "country_code": "SM",
    "continent_name": "Europe",
    "country_name": "San Marino"
  },
  {
    "continent_code": "AF",
    "country_code": "ST",
    "continent_name": "Africa",
    "country_name": "Sao Tome and Principe"
  },
  {
    "continent_code": "AS",
    "country_code": "SA",
    "continent_name": "Asia",
    "country_name": "Saudi Arabia"
  },
  {
    "continent_code": "AF",
    "country_code": "SN",
    "continent_name": "Africa",
    "country_name": "Senegal"
  },
  {
    "continent_code": "EU",
    "country_code": "RS",
    "continent_name": "Europe",
    "country_name": "Serbia"
  },
  {
    "continent_code": "AF",
    "country_code": "SC",
    "continent_name": "Africa",
    "country_name": "Seychelles"
  },
  {
    "continent_code": "AF",
    "country_code": "SL",
    "continent_name": "Africa",
    "country_name": "Sierra Leone"
  },
  {
    "continent_code": "AS",
    "country_code": "SG",
    "continent_name": "Asia",
    "country_name": "Singapore"
  },
  {
    "continent_code": "NA",
    "country_code": "SX",
    "continent_name": "North America",
    "country_name": "Sint Maarten Dutch part"
  },
  {
    "continent_code": "EU",
    "country_code": "SK",
    "continent_name": "Europe",
    "country_name": "Slovakia Slovak Republic"
  },
  {
    "continent_code": "EU",
    "country_code": "SI",
    "continent_name": "Europe",
    "country_name": "Slovenia"
  },
  {
    "continent_code": "OC",
    "country_code": "SB",
    "continent_name": "Oceania",
    "country_name": "Solomon Islands"
  },
  {
    "continent_code": "AF",
    "country_code": "SO",
    "continent_name": "Africa",
    "country_name": "Somalia"
  },
  {
    "continent_code": "AF",
    "country_code": "ZA",
    "continent_name": "Africa",
    "country_name": "South Africa"
  },
  {
    "continent_code": "AN",
    "country_code": "GS",
    "continent_name": "Antarctica",
    "country_name": "South Georgia and the South Sandwich Islands"
  },
  {
    "continent_code": "AF",
    "country_code": "SS",
    "continent_name": "Africa",
    "country_name": "South Sudan"
  },
  {
    "continent_code": "EU",
    "country_code": "ES",
    "continent_name": "Europe",
    "country_name": "Spain"
  },
  {
    "continent_code": "AS",
    "country_code": "LK",
    "continent_name": "Asia",
    "country_name": "Sri Lanka"
  },
  {
    "continent_code": "AF",
    "country_code": "SD",
    "continent_name": "Africa",
    "country_name": "Sudan"
  },
  {
    "continent_code": "SA",
    "country_code": "SR",
    "continent_name": "South America",
    "country_name": "Suriname"
  },
  {
    "continent_code": "EU",
    "country_code": "SJ",
    "continent_name": "Europe",
    "country_name": "Svalbard & Jan Mayen Islands"
  },
  {
    "continent_code": "AF",
    "country_code": "SZ",
    "continent_name": "Africa",
    "country_name": "Swaziland"
  },
  {
    "continent_code": "EU",
    "country_code": "SE",
    "continent_name": "Europe",
    "country_name": "Sweden"
  },
  {
    "continent_code": "EU",
    "country_code": "CH",
    "continent_name": "Europe",
    "country_name": "Switzerland"
  },
  {
    "continent_code": "AS",
    "country_code": "SY",
    "continent_name": "Asia",
    "country_name": "Syrian Arab Republic"
  },
  {
    "continent_code": "AS",
    "country_code": "TW",
    "continent_name": "Asia",
    "country_name": "Taiwan"
  },
  {
    "continent_code": "AS",
    "country_code": "TJ",
    "continent_name": "Asia",
    "country_name": "Tajikistan"
  },
  {
    "continent_code": "AF",
    "country_code": "TZ",
    "continent_name": "Africa",
    "country_name": "Tanzania"
  },
  {
    "continent_code": "AS",
    "country_code": "TH",
    "continent_name": "Asia",
    "country_name": "Thailand"
  },
  {
    "continent_code": "AS",
    "country_code": "TL",
    "continent_name": "Asia",
    "country_name": "Timor-Leste"
  },
  {
    "continent_code": "AF",
    "country_code": "TG",
    "continent_name": "Africa",
    "country_name": "Togo"
  },
  {
    "continent_code": "OC",
    "country_code": "TK",
    "continent_name": "Oceania",
    "country_name": "Tokelau"
  },
  {
    "continent_code": "OC",
    "country_code": "TO",
    "continent_name": "Oceania",
    "country_name": "Tonga"
  },
  {
    "continent_code": "NA",
    "country_code": "TT",
    "continent_name": "North America",
    "country_name": "Trinidad and Tobago"
  },
  {
    "continent_code": "AF",
    "country_code": "TN",
    "continent_name": "Africa",
    "country_name": "Tunisia"
  },
  {
    "continent_code": "AS",
    "country_code": "TR",
    "continent_name": "Asia",
    "country_name": "Turkey"
  },
  {
    "continent_code": "AS",
    "country_code": "TM",
    "continent_name": "Asia",
    "country_name": "Turkmenistan"
  },
  {
    "continent_code": "NA",
    "country_code": "TC",
    "continent_name": "North America",
    "country_name": "Turks and Caicos Islands"
  },
  {
    "continent_code": "OC",
    "country_code": "TV",
    "continent_name": "Oceania",
    "country_name": "Tuvalu"
  },
  {
    "continent_code": "AF",
    "country_code": "UG",
    "continent_name": "Africa",
    "country_name": "Uganda"
  },
  {
    "continent_code": "EU",
    "country_code": "UA",
    "continent_name": "Europe",
    "country_name": "Ukraine"
  },
  {
    "continent_code": "AS",
    "country_code": "AE",
    "continent_name": "Asia",
    "country_name": "United Arab Emirates"
  },
  {
    "continent_code": "EU",
    "country_code": "GB",
    "continent_name": "Europe",
    "country_name": "United Kingdom of Great Britain & Northern Ireland"
  },
  {
    "continent_code": "NA",
    "country_code": "US",
    "continent_name": "North America",
    "country_name": "United States of America"
  },
  {
    "continent_code": "OC",
    "country_code": "UM",
    "continent_name": "Oceania",
    "country_name": "United States Minor Outlying Islands"
  },
  {
    "continent_code": "NA",
    "country_code": "VI",
    "continent_name": "North America",
    "country_name": "United States Virgin Islands"
  },
  {
    "continent_code": "SA",
    "country_code": "UY",
    "continent_name": "South America",
    "country_name": "Uruguay"
  },
  {
    "continent_code": "AS",
    "country_code": "UZ",
    "continent_name": "Asia",
    "country_name": "Uzbekistan"
  },
  {
    "continent_code": "OC",
    "country_code": "VU",
    "continent_name": "Oceania",
    "country_name": "Vanuatu"
  },
  {
    "continent_code": "SA",
    "country_code": "VE",
    "continent_name": "South America",
    "country_name": "Venezuela"
  },
  {
    "continent_code": "AS",
    "country_code": "VN",
    "continent_name": "Asia",
    "country_name": "Vietnam"
  },
  {
    "continent_code": "OC",
    "country_code": "WF",
    "continent_name": "Oceania",
    "country_name": "Wallis and Futuna"
  },
  {
    "continent_code": "AF",
    "country_code": "EH",
    "continent_name": "Africa",
    "country_name": "Western Sahara"
  },
  {
    "continent_code": "AS",
    "country_code": "YE",
    "continent_name": "Asia",
    "country_name": "Yemen"
  },
  {
    "continent_code": "AF",
    "country_code": "ZM",
    "continent_name": "Africa",
    "country_name": "Zambia"
  },
  {
    "continent_code": "AF",
    "country_code": "ZW",
    "continent_name": "Africa",
    "country_name": "Zimbabwe"
  }
]